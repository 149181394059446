<template>
  <HeaderBlock :isHideSearch="false" />
  <section class="cart-section" @click.stop="promoCodeInfo = false">
    <h1>Подтверждение и оплата заказа</h1>
    <div class="products-cart">
      <div class="left">
        <div v-if="!authToken" class="checkout-items">
          <div class="checkout-item" @click="clickAuthReg(1)">
            <span>Оплата бонусами</span>
            <span>
              <a>Войдите или зарегистрируйтесь</a>, чтобы накопить или списать
              бонусы
            </span>
          </div>
        </div>
        <div class="bonus-items" v-else-if="writeOffBonus > 0 || getBonus > 0">
          <div
            @click.stop="isWriteOffBonus = false"
            :class="{ active: !isWriteOffBonus }"
          >
            <span>Получить {{ getBonus }} {{ getBonusText }}</span>
            <span v-if="isWriteOffBonus">
              При списании бонусы не начисляются
            </span>
            <span v-else>Начислим бонусы сразу после доставки заказа</span>
          </div>
          <div
            v-if="writeOffBonus > 0"
            @click.stop="isWriteOffBonus = true"
            :class="{ active: isWriteOffBonus }"
          >
            <span>Списать {{ writeOffBonus }} {{ writeOffBonusText }}</span>
            <span>
              Можно оплатить до {{ lpLevel.percent_write_off }}% бонусами
            </span>
          </div>
          <div v-else class="disabled">
            <span>Списать бонусы</span>
            <span>На балансе 0 бонусов</span>
          </div>
        </div>
        <div class="checkout-items">
          <div
            v-if="paymentCards.length > 0 && selectPaymentCard"
            @click.stop="isSelectPaymentCard = true"
            class="checkout-item"
          >
            <span>Оплата картой {{ selectPaymentCard.bankName }}</span>
            <span>
              {{ selectPaymentCard.pan }} ({{ selectPaymentCard.paymentName }})
            </span>
          </div>
          <div
            v-else-if="paymentCards.length > 0"
            @click.stop="isSelectPaymentCard = true"
            class="checkout-item"
          >
            <span>Оплата новой картой</span>
            <span>Введите данные карты на странице оплаты</span>
          </div>
          <div class="checkout-item" @click="clickAuthReg(2)" v-if="!authToken">
            <span>Мои данные</span>
            <span><a>Укажите данные получателя</a></span>
          </div>
          <div
            v-else
            class="checkout-item"
            @click.stop="isOpenProfileName = true"
          >
            <span>{{ profile.firstName }} {{ profile.lastName }}</span>
            <span v-if="profile.email">
              {{ getProfilePhone() }}; {{ profile.email }}
            </span>
            <span v-else>{{ getProfilePhone() }}</span>
          </div>
          <div
            v-if="selectDelivery && !isOnlyVirtual"
            class="checkout-item"
            @click.stop="isDeliveries = true"
          >
            <span v-if="selectDelivery.type === 2">Доставка курьером</span>
            <span v-else-if="selectDelivery.info_point.is_postamat">
              Почтомат
              {{ selectDelivery.info_point.delivery_service_name }}
            </span>
            <span v-else>
              Пункт самовывоза
              {{ selectDelivery.info_point.delivery_service_name }}
            </span>
            <span v-if="selectDelivery.type === 1">
              {{ selectDelivery.info_point.address }}
            </span>
            <span v-else>{{ selectDelivery.address_raw }}</span>
          </div>
          <div
            v-else-if="deliveries.length > 0 && !isOnlyVirtual"
            class="checkout-item"
            @click.stop="isDeliveries = true"
          >
            <span>Доставка</span>
            <span>Выберите доставку</span>
          </div>
          <div
            v-else-if="!isOnlyVirtual"
            class="checkout-item"
            @click.stop="isAddDelivery = true"
          >
            <span>Способ доставки</span>
            <span v-if="authToken">Выберите способ и адрес доставки</span>
            <span v-else><a>Выберите способ и адрес доставки</a></span>
          </div>
          <div v-if="expressCourierType" class="checkout-item express-courier">
            <span>Скорость доставки</span>
            <span>
              <small
                :class="{ active: checkedExpressCourier }"
                @click.stop="checkedExpressCourier = true"
              >
                Экспресс, сегодня за {{ expressCourierType }} часа
              </small>
              <small
                :class="{ active: !checkedExpressCourier }"
                @click.stop="checkedExpressCourier = false"
              >
                Стандарт, 3-4 дня
              </small>
            </span>
          </div>
        </div>
        <div class="bonus-items courier-items" v-if="expressCourierType">
          <div
            :class="{ active: checkedExpressCourier }"
            @click.stop="checkedExpressCourier = true"
          >
            <span>Экспресс</span>
            <span>Сегодня за {{ expressCourierType }} часа</span>
          </div>
          <div
            :class="{ active: !checkedExpressCourier }"
            @click.stop="checkedExpressCourier = false"
          >
            <span>Стандарт</span>
            <span>3-4 дня</span>
          </div>
        </div>
        <div
          class="product-items"
          :class="{ 'display-all': isDisplayBaskets }"
          @click.stop="nextCart"
        >
          <span>Товары в заказе</span>
          <div
            v-for="(item, index) in productBaskets"
            :key="item"
            class="product-item"
          >
            <a
              class="image"
              :style="{
                background: item.product.images[0].image.background_color,
              }"
            >
              <img :src="item.product.images[0].image.image_object_small" />
              <small v-if="item.quantity > 1">{{ item.quantity }}</small>
              <span
                v-if="
                  index === 5 && productBaskets.length > 6 && !isDisplayBaskets
                "
                @click.stop="clickDisplayBaskets"
              >
                +{{ productCountPlus }}
              </span>
              <span
                v-if="
                  index === 2 && productBaskets.length > 3 && !isDisplayBaskets
                "
                @click.stop="clickDisplayBaskets"
              >
                +{{ productCountPlusMobile }}
              </span>
            </a>
          </div>
        </div>
      </div>
      <div class="right">
        <CartInfoBlock
          v-if="isDisplayCartInfoBlock && productBaskets.length > 0"
          :products="productBaskets"
          :isOnlyVirtual="isOnlyVirtual"
          :city="city"
        />
        <button
          v-if="basketTotalAmount === 0 && writeOffBonus > 0"
          @click.stop="createOrder"
          class="checkout-button-order"
        >
          Оплатить бонусами
        </button>
        <button
          v-else-if="paymentCards.length > 0 && selectPaymentCard"
          @click.stop="createOrder"
          class="checkout-button-order"
        >
          Оплатить
          <span>
            <PaymentLogoBlock :card="selectPaymentCard" />
          </span>
        </button>
        <button v-else @click.stop="createOrder" class="checkout-button-order">
          Оплатить заказ
        </button>
      </div>
    </div>
  </section>
  <div v-if="isSelectPaymentCard" class="payment-card-popup shop-popup">
    <div class="content">
      <img
        @click="isSelectPaymentCard = false"
        src="@/assets/images/shop-close.svg"
        class="close"
      />
      <h5>Способ оплаты</h5>
      <div
        v-for="item in paymentCards"
        :key="item"
        class="item"
        :class="{ active: selectPaymentCardId === item.cardId }"
        @click.stop="
          (selectPaymentCard = item),
            (selectPaymentCardId = item.cardId),
            (isSelectPaymentCard = false)
        "
      >
        <span>{{ item.bankName }}</span>
        <span>{{ item.pan }} ({{ item.paymentName }})</span>
      </div>
      <div
        class="select-none"
        @click.stop="
          (selectPaymentCard = null),
            (selectPaymentCardId = null),
            (isSelectPaymentCard = false)
        "
      >
        Оплатить новой картой
      </div>
    </div>
  </div>
  <div v-if="isDeliveries" class="deliveries-popup shop-popup">
    <div class="content">
      <img
        @click="isDeliveries = false"
        src="@/assets/images/shop-close.svg"
        class="close"
      />
      <h5>Адрес доставки</h5>
      <div
        v-for="item in deliveries"
        :key="item"
        class="item"
        :class="{ active: selectDeliveryId == item.id }"
        @click.stop="
          (selectDelivery = item),
            (selectDeliveryId = item.id),
            (isDeliveries = false)
        "
      >
        <span v-if="item.type === 2">Доставка курьером</span>
        <span v-else-if="item.info_point.is_postamat">
          Почтомат {{ item.info_point.delivery_service_name }}
        </span>
        <span v-else>
          Пункт самовывоза {{ item.info_point.delivery_service_name }}
        </span>
        <span v-if="item.type === 1">{{ item.info_point.address }}</span>
        <span v-else>{{ item.address_raw }}</span>
      </div>
      <div
        class="select-none"
        @click.stop="(isDeliveries = false), (isAddDelivery = true)"
      >
        Добавить новый адрес
      </div>
    </div>
  </div>
  <AddDeliveryPopupBlock
    v-if="isAddDelivery"
    :deliveriesPoints="deliveriesPoints"
    :isCreate="true"
    :isSettings="false"
    :isCheckout="true"
  />
  <ErrorTooltipBlock
    v-if="errorTooltip === 'delivery'"
    :header="'Ошибка'"
    :text="'Укажите доставку'"
  />
  <ErrorTooltipBlock
    v-if="errorTooltip === 'user'"
    :header="'Ошибка'"
    :text="'Укажите данные получателя'"
  />
  <ErrorTooltipBlock
    v-if="errorTooltip === 'not-delivery'"
    :header="'Выбранный пункт выдачи не доступен'"
    :text="'Пожалуйста выберите другой пункт выдачи'"
  />
  <ErrorTooltipBlock
    v-if="errorTooltip === 'available'"
    :header="'Ошибка'"
    :text="'Недостаточно в наличие'"
  />
  <FooterMinBlock />
  <ProfileNameBlock
    v-if="isOpenProfileName"
    :profile="profile"
    :isCheckout="isCheckout"
    :isPartner="false"
    :isLastName="true"
    :isDisplayEmail="true"
  />
  <AuthRegCheckoutBlock
    v-if="isOpenAuth"
    :authTextH5="authTextH5"
    :authTextH6="authTextH6"
    :delivery="selectDelivery"
  />
</template>

<script>
import jsMixin from "@/mixins/jsMixin";
import mainMixin from "@/mixins/mainMixin";
import profileMixin from "@/mixins/profileMixin";
import productMixin from "@/mixins/productMixin";
import HeaderBlock from "@/components/HeaderBlock.vue";
import FooterMinBlock from "@/components/FooterMinBlock.vue";
import ProfileNameBlock from "@/components/ProfileNameBlock.vue";
import PaymentLogoBlock from "@/components/PaymentLogoBlock.vue";
import ErrorTooltipBlock from "@/components/ErrorTooltipBlock.vue";
import CartInfoBlock from "@/components/CartInfoBlock.vue";
import AddDeliveryPopupBlock from "@/components/AddDeliveryPopupBlock.vue";
import AuthRegCheckoutBlock from "@/components/AuthRegCheckoutBlock.vue";
import api from "@/api";

export default {
  mixins: [jsMixin, mainMixin, profileMixin, productMixin],
  components: {
    HeaderBlock,
    FooterMinBlock,
    ProfileNameBlock,
    PaymentLogoBlock,
    ErrorTooltipBlock,
    CartInfoBlock,
    AddDeliveryPopupBlock,
    AuthRegCheckoutBlock,
  },
  data() {
    return {
      basketTotalAmount: 1,
      isDisplayCartInfoBlock: false,
      productBaskets: [],
      isOpenProfileName: false,
      paymentCards: [],
      selectPaymentCardId: null,
      selectPaymentCard: null,
      isSelectPaymentCard: false,
      deliveries: [],
      selectDelivery: null,
      selectDeliveryId: null,
      errorTooltip: null,
      isDeliveries: false,
      isAddDelivery: false,
      selectPointId: null,
      selectCourierServiceId: null,
      courierServices: [],
      city: "",
      isCheckout: false,
      isWriteOffBonus: false,
      lpLevel: {},
      lpBalance: 0,
      getBonus: 0,
      writeOffBonus: 0,
      getBonusText: "",
      writeOffBonusText: "",
      isOpenAuth: false,
      authTextH5: "",
      authTextH6: "",
      deliveriesPoints: "",
      maxFreePackage: 0,
      productCountPlus: 0,
      productCountPlusMobile: 0,
      isOnlyVirtual: true,
      isCreateOrder: false,
      promoCodeInfo: false,
      expressCourierZone: null,
      expressCourierType: 0,
      checkedExpressCourier: false,
      attempt: null,
      isDisplayBaskets: false,
    };
  },
  async mounted() {
    document.querySelectorAll("header .href-to")[0].classList.add("d-none");
    document.querySelectorAll("header .href-to")[1].classList.add("d-none");
    document.querySelector("header .basket-button").classList.remove("d-none");
    document.querySelector(".search-block").classList.add("d-none");
    document.querySelector("nav").classList.add("d-none");
    window.scrollTo({ top: 2, left: 0, behavior: "smooth" });
    window.addEventListener("unload", function () {
      alert("error");
    });
    window.addEventListener("hashchange", function () {
      alert("error");
    });
    if (localStorage.getItem("redirectBackPayment")) {
      localStorage.removeItem("redirectBackPayment");
      window.location = "/personal/orders";
    }
    document.title = "Подтверждение и оплата заказа";
    document.getElementsByTagName("meta")["description"].content =
      "Подтверждение и оплата заказа";
    this.city = localStorage.getItem("city");
    const urlParams = new URLSearchParams(window.location.search);
    const isAuth = urlParams.get("is-auth");
    if (isAuth) {
      history.pushState(
        null,
        null,
        location.protocol + "//" + location.host + location.pathname
      );
    }
    const questionnaire = urlParams.get("questionnaire");
    if (questionnaire) this.getCartQuestionnaire(questionnaire);
    else this.getCart();
    let that = this;
    document.addEventListener("keydown", function (evt) {
      if (evt.which === 27) {
        that.isOpenAuth = false;
        that.isSelectPaymentCard = false;
        that.isDeliveries = false;
        that.isAddDelivery = false;
        that.isOpenPopup(false);
      }
    });
    try {
      const result = await api.shop.getDeliveryPointsMaps();
      this.deliveriesPoints = result["data"]["points"];
    } catch (error) {
      console.log(error);
    }
    if (this.authToken) {
      let profile = this.profile;
      if (!profile.firstName || !profile.lastName || !profile.email) {
        this.isCheckout = true;
        this.isOpenProfileName = true;
      }
      try {
        const result = await api.profile.getUserBonus();
        this.lpBalance = result["data"]["results"][0]["lp_balance"];
        this.lpLevel = result["data"]["results"][0]["lp_level"];
      } catch (error) {
        console.log(error);
      }
      try {
        const result = await api.profile.getUserPaymentCard();
        let cardLast = { updatedAt: 0 };
        for (let item in result["data"]["results"]) {
          item = result["data"]["results"][item];
          let card = {
            cardId: item["card_id"],
            pan: item["pan"],
            bankName: item["bank_name"],
            paymentName: item["payment_name"],
            panEnd: item["pan"].slice(-6),
            updatedAt: new Date(item["updated_at"]).getTime(),
            isDefault: item["is_default"],
          };
          this.paymentCards.push(card);
          if (card["isDefault"]) {
            this.selectPaymentCardId = card["cardId"];
            this.selectPaymentCard = card;
          }
          if (card["updatedAt"] > cardLast["updatedAt"]) cardLast = card;
        }
        if (!this.selectPaymentCardId) {
          this.selectPaymentCardId = cardLast["cardId"];
          this.selectPaymentCard = cardLast;
        }
        this.totalBasket();
      } catch (error) {
        console.log(error);
      }
    }
    try {
      const result = await api.shop.getCourierServicePrice();
      this.courierServices = result["data"]["results"];
    } catch (error) {
      console.log(error);
    }
    if (this.authToken) {
      try {
        const result = await api.profile.getUserDelivery();
        let deliveryLast = { updatedAt: 0 };
        for (let item in result["data"]["results"]) {
          item = result["data"]["results"][item];
          let cost = 0;
          if (item["type"] === 1) cost = item["info_point"]["cost_of_delivery"];
          let delivery = {
            id: item["id"],
            type: item["type"],
            city: item["city"],
            address_raw: item["address_raw"],
            pointId: item["point"],
            info_point: item["info_point"],
            courier: {
              geo_lat: item["geo_lat"],
              geo_lon: item["geo_lon"],
            },
            cost: cost,
            updatedAt: new Date(item["updated_at"]).getTime(),
            isDefault: item["is_default"],
          };
          this.deliveries.push(delivery);
          if (delivery["isDefault"]) {
            this.selectDeliveryId = delivery["id"];
            this.selectDelivery = delivery;
          }
          if (delivery["updatedAt"] > deliveryLast["updatedAt"]) {
            deliveryLast = delivery;
          }
        }
        if (!this.selectDeliveryId && deliveryLast["id"]) {
          this.selectDeliveryId = deliveryLast["id"];
          this.selectDelivery = deliveryLast;
        }
        localStorage.setItem("isPageCheckout", "1");
        this.totalBasket();
      } catch (error) {
        console.log(error);
      }
    }
    if (!this.authToken) this.getCreateDelivery();
  },
  watch: {
    checkedExpressCourier() {
      this.totalBasket();
    },
    isWriteOffBonus() {
      this.totalBasket();
    },
    writeOffBonus() {
      this.getBonusText = this.getText(this.getBonus);
      this.writeOffBonusText = this.getText(this.writeOffBonus);
    },
    selectDeliveryId(value, oldValue) {
      if (!this.selectDelivery) return false;
      if (this.selectDelivery.type === 1) {
        this.selectPointId = this.selectDelivery.pointId;
        this.expressCourierZone = null;
        this.expressCourierType = 0;
        this.selectCourierServiceId = null;
      } else {
        this.selectPointId = null;
        for (let item in this.courierServices) {
          item = this.courierServices[item];
          if (item["city_name"] !== this.selectDelivery.city) continue;
          this.selectCourierServiceId = item["id"];
          this.selectDelivery.cost = item["cost_of_delivery"];
          // if (this.profile.id === 2) this.checkExpressCourier();
          this.checkExpressCourier();
        }
      }
      if (!oldValue) return false;
      this.totalBasket();
    },
    selectDelivery(value) {
      if (!value) return false;
      if (value.type === 1) {
        if (value.info_point.is_deleted) {
          this.selectDelivery = null;
          this.selectDeliveryId = null;
          this.errorTooltip = "not-delivery";
          this.isDeliveries = false;
        }
      }
    },
    isSelectPaymentCard(isOpen) {
      this.isOpenPopup(isOpen);
    },
    isDeliveries(isOpen) {
      this.isOpenPopup(isOpen);
    },
    isAddDelivery(isOpen) {
      this.isOpenPopup(isOpen);
    },
    isOpenAuth(isOpen) {
      this.isOpenPopup(isOpen);
    },
    isOpenProfileName(isOpen) {
      this.isOpenPopup(isOpen);
    },
  },
  methods: {
    clickDisplayBaskets() {
      this.isDisplayBaskets = true;
    },
    getProfilePhone() {
      return this.profile.phone.replace(/[^\d]/g, "");
    },
    async checkExpressCourier() {
      // console.log(this.selectDelivery);
      this.expressCourierZone = null;
      this.expressCourierType = 0;
      if (!this.selectDeliveryId) return false;
      if (!this.selectDelivery["courier"]) return false;
      if (!this.selectDelivery["courier"]["geo_lat"]) return false;
      if (!this.selectDelivery["courier"]["geo_lon"]) return false;
      try {
        const result = await api.shop.checkExpressCourier(
          this.selectDelivery["courier"]["geo_lat"],
          this.selectDelivery["courier"]["geo_lon"]
        );
        if (result["data"]["is_darkstore_5"]) {
          this.expressCourierZone = result["data"]["is_darkstore_5"];
          this.expressCourierType = 1;
        } else if (result["data"]["is_darkstore_10"]) {
          this.expressCourierZone = result["data"]["is_darkstore_10"];
          this.expressCourierType = 2;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async createOrder() {
      if (this.isCreateOrder) return false;
      try {
        window.ym(81846475, "reachGoal", "checkout-button-order");
      } catch (e) {
        console.log(e);
      }
      let clickHash = localStorage.getItem("clickHash");
      let clickAttribution = localStorage.getItem("clickAttribution");
      if (!clickHash) clickHash = null;
      let attributionType = 5;
      if (clickAttribution) {
        clickAttribution = new Date(clickAttribution);
        clickAttribution = new Date().getTime() - clickAttribution.getTime();
        clickAttribution = Math.ceil(Math.abs(clickAttribution));
        clickAttribution = clickAttribution / (1000 * 3600 * 24);
        if (sessionStorage.getItem("clickAttributionSession")) {
          attributionType = 1;
        } else if (clickAttribution < 7) attributionType = 2;
        else if (clickAttribution < 30) attributionType = 3;
        else if (clickAttribution < 90) attributionType = 4;
      }
      try {
        let data = {
          click_hash: clickHash,
          delivery_id: this.selectDeliveryId,
          attribution_type: attributionType,
          is_write_off_bonus: this.isWriteOffBonus,
          max_free_package: this.maxFreePackage,
          products: {},
        };
        if (this.attempt) data.attempt_id = this.attempt.id;
        else {
          let promoCode = localStorage.getItem("cart_promoCode");
          if (promoCode) data.promo_code = promoCode;
        }
        if (!this.selectDelivery && !this.isOnlyVirtual) {
          this.errorTooltip = "delivery";
          return false;
        }
        if (!this.authToken) {
          this.errorTooltip = "user";
          return false;
        }
        if (!this.profile.firstName) {
          this.errorTooltip = "user";
          return false;
        }
        for (let item in this.productBaskets) {
          item = this.productBaskets[item];
          let productBasket = localStorage.getItem(
            "statisticsProductBasket" + item.product["id"]
          );
          if (productBasket) {
            data.products[item.product["id"]] = productBasket;
            localStorage.removeItem(
              "statisticsProductBasket" + item.product["id"]
            );
          }
        }
        if (this.selectPaymentCardId) {
          data["select_payment_card_id"] = this.selectPaymentCardId;
        }
        if (this.checkedExpressCourier && this.expressCourierZone) {
          data["darkstore_id"] = this.expressCourierZone;
        }
        try {
          window.ym(81846475, "getClientID", function (clientID) {
            data.ym_client_id = clientID;
          });
        } catch (e) {
          console.log(e);
        }
        this.isCreateOrder = true;
        let result;
        if (this.attempt) {
          result = await api.shop.postQuestionnaireCreateOrder(data);
        } else result = await api.shop.postShopCreateOrder(data);
        if (result["data"]["error"]) {
          if (result["data"]["error"] === "available") {
            this.errorTooltip = "available";
            this.isCreateOrder = false;
          } else window.location = "/personal/orders";
        } else {
          localStorage.removeItem("cart_promoCode");
          localStorage.removeItem("isPageCheckout");
          let dataItem = {
            ecommerce: {
              currencyCode: "RUB",
              purchase: {
                actionField: {
                  id: String(result["data"]["id"]),
                },
                products: [],
              },
            },
          };
          for (let item in this.productBaskets) {
            item = this.productBaskets[item];
            dataItem.ecommerce.purchase.products.push({
              id: String(item.product["id"]),
              name: String(item.product["headline_preview"]),
              price: item.price,
              quantity: item.quantity,
            });
          }
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push(dataItem);
          setTimeout(function () {
            if (result["data"]["is_paid"]) {
              window.location =
                "/personal/orders?order_id=" + result["data"]["id"];
            } else {
              localStorage.setItem("redirectBackPayment", "1");
              window.location = result["data"]["url"];
            }
          }, 100);
        }
      } catch (error) {
        console.log(error);
        this.isCreateOrder = false;
      }
    },
    async getCartQuestionnaire(value) {
      try {
        const result = await api.profile.getQuestionnaireAttemptBasket(value);
        console.log(result);
        this.attempt = result["data"]["results"][0];
        if (!this.authToken) {
          location.href =
            "/questionnaire/" + this.attempt["shop_page"][0]["url"];
        }
        if (this.profile.id !== this.attempt["user_id"]) {
          location.href =
            "/questionnaire/" + this.attempt["shop_page"][0]["url"];
        }
        for (let item in this.attempt["products"]) {
          item = this.attempt["products"][item];
          let product = item["product"];
          let price = item["total"] / item["quantity"];
          let priceOld = product["website_price"];
          let priceOldTotal = priceOld * item["quantity"];
          this.productBaskets.push({
            product: product,
            price: price,
            priceTotal: item["total"],
            priceOld: priceOld,
            priceOldTotal: priceOldTotal,
            quantity: item["quantity"],
          });
          if (product["bonus"] > 0) {
            this.getBonus += product["bonus"] * item["quantity"];
          }
        }
        this.productCountPlus = this.productBaskets.length - 5;
        this.productCountPlusMobile = this.productBaskets.length - 3;
        if (this.productBaskets.length === 0) location.href = "/cart";
        else this.totalBasket();
        this.isOnlyVirtual = false;
      } catch (error) {
        console.log(error);
        location.href = "/";
      }
    },
    async getCart() {
      if (this.authToken) {
        try {
          const result = await api.shop.getShopProductUserBaskets();
          for (let item in result["data"]["results"]) {
            item = result["data"]["results"][item];
            let product = item["product"];
            let price = product["website_price"];
            let priceOld = null;
            let priceOldTotal = null;
            if (product["discount_price"]) {
              price = product["discount_price"];
              priceOld = product["website_price"];
              priceOldTotal = priceOld * item["quantity"];
            }
            this.productBaskets.push({
              product: product,
              price: price,
              priceTotal: price * item["quantity"],
              priceOld: priceOld,
              priceOldTotal: priceOldTotal,
              quantity: item["quantity"],
              isPromo: item["is_promo"],
              promoName: item["promo_name"],
              promoCodes: item["promo_codes"],
            });
            if (product["bonus"] > 0) {
              this.getBonus += product["bonus"] * item["quantity"];
            }
          }
          this.productCountPlus = this.productBaskets.length - 5;
          this.productCountPlusMobile = this.productBaskets.length - 3;
          if (this.productBaskets.length === 0) location.href = "/cart";
          else this.totalBasket();
        } catch (error) {
          console.log(error);
        }
      } else {
        for (let i = 0; i < localStorage.length; i++) {
          let values = localStorage.key(i).split("shop_basketProduct_");
          if (values.length > 1) {
            try {
              const result = await api.shop.getShopProductsInfo(values[1]);
              let product = result["data"]["results"][0];
              let price = product["website_price"];
              let priceOld = null;
              let priceOldTotal = null;
              let quantity = localStorage.getItem(
                "shop_basketProduct_" + values[1]
              );
              if (product["discount_price"]) {
                price = product["discount_price"];
                priceOld = product["website_price"];
                priceOldTotal = priceOld * Number(quantity);
              }
              this.productBaskets.push({
                price: price,
                priceTotal: price * Number(quantity),
                priceOld: priceOld,
                priceOldTotal: priceOldTotal,
                product: product,
                quantity: Number(quantity),
                isPromo: product["is_promo"],
                promoName: product["promo_name"],
                promoCodes: product["promo_codes"],
              });
              if (product["bonus"] > 0) {
                this.getBonus += product["bonus"] * Number(quantity);
              }
            } catch (error) {
              console.log(error);
            }
          }
        }
        this.productCountPlus = this.productBaskets.length - 5;
        this.productCountPlusMobile = this.productBaskets.length - 3;
        if (this.productBaskets.length === 0) location.href = "/cart";
        else this.totalBasket();
      }
      this.isOnlyVirtual = true;
      for (let item in this.productBaskets) {
        if (this.productBaskets[item].product.type !== 6) {
          this.isOnlyVirtual = false;
          break;
        }
      }
    },
    totalBasket() {
      this.isDisplayCartInfoBlock = false;
      this.$nextTick(() => {
        this.isDisplayCartInfoBlock = true;
      });
    },
    clickAuthReg(type) {
      if (type === 1) {
        this.authTextH5 =
          "Зарегиструйтесь, чтобы получить кешбек " +
          this.getBonus +
          " баллов на текущий заказ";
        this.authTextH6 =
          "чтобы получить кешбек " + this.getBonus + " баллов на текущий заказ";
      } else {
        this.authTextH5 = "Введите номер телефона";
        this.authTextH6 = "";
      }
      this.isOpenAuth = true;
    },
    getText(value) {
      let result = value.toString();
      if (value > 4 && value < 21) {
        result = "бонусов";
      } else if (result.slice(-1) === "1") {
        result = "бонус";
      } else if (result.slice(-1) === "2") {
        result = "бонуса";
      } else if (result.slice(-1) === "3") {
        result = "бонуса";
      } else if (result.slice(-1) === "4") {
        result = "бонуса";
      } else result = "бонусов";
      return result;
    },
    async getCreateDelivery() {
      let createDeliveryId = localStorage.getItem("createDeliveryId");
      let createDeliveryType = localStorage.getItem("createDeliveryType");
      if (createDeliveryId && createDeliveryType) {
        if (createDeliveryType === "1") {
          let pointId = Number(localStorage.getItem("createDeliveryPointId"));
          let infoPoint = {};
          try {
            const result = await api.shop.getInfoDeliveryPoint(pointId);
            infoPoint = result["data"]["results"][0];
          } catch (error) {
            console.log(error);
          }
          let item = {
            id: Number(createDeliveryId),
            type: 1,
            city: null,
            address_raw: null,
            pointId: pointId,
            info_point: infoPoint,
            cost: infoPoint["cost_of_delivery"],
          };
          this.deliveries.push(item);
          this.selectDelivery = item;
          this.selectDeliveryId = item["id"];
        }
        if (createDeliveryType === "2") {
          let item = {
            id: Number(createDeliveryId),
            type: 2,
            city: localStorage.getItem("createDeliveryCity"),
            address_raw: localStorage.getItem("createDeliveryAddressRaw"),
            pointId: null,
            info_point: {},
            cost: 199,
            courier: {
              city: localStorage.getItem("createDeliveryCity"),
              street: localStorage.getItem("createDeliveryStreet"),
              house: localStorage.getItem("createDeliveryHouse"),
              flat: localStorage.getItem("createDeliveryFlat"),
              zip_code: localStorage.getItem("createDeliveryZipCode"),
              geo_lat: localStorage.getItem("createDeliveryGeoLat"),
              geo_lon: localStorage.getItem("createDeliveryGeoLon"),
              comment: localStorage.getItem("createDeliveryComment"),
            },
          };
          this.deliveries.push(item);
          this.selectDelivery = item;
          this.selectDeliveryId = item["id"];
        }
      }
    },
    nextCart() {
      if (this.attempt) {
        location.href = "/personal/questionnaire/" + this.attempt["code"];
      } else window.location.href = "/cart";
    },
  },
};
</script>
