export default {
  mounted() {
    setTimeout(function () {
      let isScroll25, isScroll50, isScroll75, isScroll100;
      let heightY = document.documentElement.scrollHeight;
      let scroll25 = (25 * heightY) / 100;
      let scroll50 = (50 * heightY) / 100;
      let scroll75 = (75 * heightY) / 100;
      let scroll100 = heightY;
      window.addEventListener("scroll", function () {
        let scrollY = window.innerHeight + window.scrollY;
        if (scrollY >= scroll25 && !isScroll25) {
          isScroll25 = true;
          try {
            window.ym(81846475, "reachGoal", "25");
            isScroll25 = true;
          } catch (e) {
            console.log(e);
          }
        }
        if (scrollY >= scroll50 && !isScroll50) {
          isScroll50 = true;
          try {
            window.ym(81846475, "reachGoal", "50");
            isScroll25 = true;
          } catch (e) {
            console.log(e);
          }
        }
        if (scrollY >= scroll75 && !isScroll75) {
          isScroll75 = true;
          try {
            window.ym(81846475, "reachGoal", "75");
            isScroll25 = true;
          } catch (e) {
            console.log(e);
          }
        }
        if (scrollY >= scroll100 && !isScroll100) {
          isScroll100 = true;
          try {
            window.ym(81846475, "reachGoal", "100");
            isScroll25 = true;
          } catch (e) {
            console.log(e);
          }
        }
      });
    }, 1000);
  },
};
