<template>
  <div>
    <HeaderBlock :isHideSearch="false" />
    <section class="catalog-section search-section page-section">
      <h1 v-if="count > 0 && locale.LOCALE === 'ru'">
        По запросу «
        <a @click.stop="openSearch">{{ query }}</a>
        » найдено {{ count }} {{ countText }}
      </h1>
      <h1 v-else-if="count > 0">
        {{ count }} products found for the query «<a>{{ query }}</a>
        »
      </h1>
      <h1 v-else-if="locale.LOCALE === 'ru'">
        По запросу «{{ query }}» ничего не найдено
      </h1>
      <h1 v-else>Nothing was found for the query «{{ query }}»</h1>
      <h3 v-if="count === 0" v-html="locale.TEXTS.searchResultNoneH3"></h3>
      <div v-if="products.length > 0" class="products-items">
        <ProductItemBlock
          v-for="item in products"
          :product="item"
          :key="item"
          :searchSessionHash="searchSessionHash"
          :trackerPromoCodeAmount="trackerPromoCodeAmount"
          :trackerPromoCodePercent="trackerPromoCodePercent"
          :isFavorite="true"
          :isBasket="true"
          :type="'catalog'"
          :sourcePage="6"
        />
      </div>
      <div
        v-if="isDisplayLot"
        @click="displayProducts(pageNumber + 1)"
        class="lot-loading-products"
      >
        <span>{{ locale.TEXTS.lotDisplay }}</span>
      </div>
      <div v-if="count === 0">
        <h2>{{ locale.TEXTS.searchResultNoneH2 }}</h2>

        <div v-if="popularProducts.length > 0" class="products-items">
          <ProductItemBlock
            v-for="item in popularProducts"
            :product="item"
            :key="item"
            :searchSessionHash="searchSessionHash"
            :trackerPromoCodeAmount="trackerPromoCodeAmount"
            :trackerPromoCodePercent="trackerPromoCodePercent"
            :isFavorite="true"
            :isBasket="true"
            :type="'catalog'"
            :sourcePage="6"
          />
        </div>
      </div>
    </section>
  </div>
  <FooterBlock />
</template>

<script>
import mainMixin from "@/mixins/mainMixin";
import productMixin from "@/mixins/productMixin";
import HeaderBlock from "@/components/HeaderBlock.vue";
import FooterBlock from "@/components/FooterBlock.vue";
import ProductItemBlock from "@/components/ProductItemBlock.vue";
import Locale from "@/api/locale";
import api from "@/api";
import Const from "@/api/const";

export default {
  mixins: [mainMixin, productMixin],
  components: {
    HeaderBlock,
    FooterBlock,
    ProductItemBlock,
  },
  props: {
    count: Number,
    query: String,
    searchSessionHash: String,
  },
  data() {
    return {
      locale: Locale,
      pageNumber: 1,
      products: [],
      isDisplayLot: false,
      countText: "",
      popularProducts: [],
      trackerPromoCodePercent: 0,
      trackerPromoCodeAmount: 0,
    };
  },
  async mounted() {
    let promoCode = localStorage.getItem("tracker_promoCode");
    if (promoCode) {
      try {
        const result = await api.shop.checkPromoCode(promoCode);
        if (result["data"]["percent"] > 0) {
          this.trackerPromoCodePercent = result["data"]["percent"];
        }
        if (result["data"]["amount"] > 0) {
          this.trackerPromoCodeAmount = result["data"]["amount"];
        }
      } catch (error) {
        console.log(error);
      }
    }
    const urlParams = new URLSearchParams(window.location.search);
    let productRedirect = urlParams.get("product");
    if (productRedirect) {
      let hrefUrl = "/catalog/" + productRedirect;
      hrefUrl = hrefUrl + "?searchSessionHash=" + this.searchSessionHash;
      location.href = hrefUrl;
    }
    if (this.count > 0) {
      this.countText = this.count.toString();
      if (this.count > 4 && this.count < 21) {
        this.countText = "товаров";
      } else if (this.countText.slice(-1) === "1") {
        this.countText = "товар";
      } else if (this.countText.slice(-1) === "2") {
        this.countText = "товара";
      } else if (this.countText.slice(-1) === "3") {
        this.countText = "товара";
      } else if (this.countText.slice(-1) === "4") {
        this.countText = "товара";
      } else this.countText = "товаров";
      document.title =
        "По запросу «" +
        this.query +
        "» найдено " +
        this.count +
        " " +
        this.countText;
    } else {
      document.title = "По запросу «" + this.query + "» ничего не найдено";
      try {
        const result = await api.shop.getShopSearchPopularProducts(6);
        this.popularProducts = result["data"]["results"];
      } catch (error) {
        console.log(error);
      }
    }
    this.displayProducts(1);
  },
  methods: {
    openSearch() {
      if (window.innerWidth < 1200) {
        document.body.style.overflow = "hidden";
      }
      document.body.classList.add("search-body");
      this.$nextTick(() => {
        document.getElementById("search_value").focus();
      });
    },
    async displayProducts(page) {
      this.pageNumber = page;
      let payload = {
        search_session_hash: this.searchSessionHash,
        page: this.pageNumber,
      };
      try {
        const result = await api.shop.postShopSearchSessionProducts(payload);
        let count = 0;
        let productRedirect;
        for (let product in result["data"]["products"]) {
          product = result["data"]["products"][product];
          for (let image in product["images"]) {
            product["images"][image]["image"]["image_object_middle"] =
              Const.API_URL +
              product["images"][image]["image"]["image_object_middle"];
          }
          if (product["type"] !== 2) {
            productRedirect = product;
            count += 1;
          }
          this.products.push(product);
        }
        if (count === 1 && productRedirect && this.count < 10) {
          let hrefUrl = "/catalog/" + productRedirect.shop_page[0].url;
          hrefUrl = hrefUrl + "?searchSessionHash=" + this.searchSessionHash;
          location.href = hrefUrl;
        } else if (this.count > this.products.length) {
          this.isDisplayLot = true;
        } else this.isDisplayLot = false;
        let that = this;
        setTimeout(function () {
          that.updateNav();
        }, 100);
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
